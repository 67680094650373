.topbar {
    background-color: var(--shadow-color);
}

.article-container {
    margin-top: 65px;
}

.main-container {
    padding-bottom: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.main-content {
    width: 70%;
}

p {
    margin-top: 10px;
}

@media screen and (max-width: 678px) {
    .main-content {
        width: 90%;
    }
}

@media screen and (max-width: 450px) {
    .main-content {
        width: 92%;
    }
}

.article-cover-image {
    width: 100%;
    aspect-ratio: 2 / 1;
    margin-bottom: 30px;
    overflow: hidden;
}

.article-cover-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.article-title {
    margin-top: 0;
    font-size: 34px;
    color: #333333;
}

.article-subtitle {
    font-size: 18px;
    color: #666666;
    line-height: 1.55;
    margin-top: 10px;
    margin-bottom: 30px;
}

.article-content {
    font-size: 16px;
    color: #666666;
    line-height: 2.0;
}

@media screen and (min-width: 1200px) {
    .article-cover-image {
        aspect-ratio: 3 / 1;
    }
}

@media screen and (max-width: 768px) {
    .article-cover-image {
        aspect-ratio: 3 / 2;
    }
    .article-title {
        font-size: 24px;
    }
    .article-subtitle {
        font-size: 16px;
    }
    .article-content {
        font-size: 14px;
    }
}