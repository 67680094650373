.image_input {
    display: none;
}

.auth_image {
    position: relative;
    width: 100%;
    margin-top: 10px;
    aspect-ratio: 3 /2 !important;
    overflow: hidden;
}

.auth_image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.label_auth_input {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 8;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.error {
    background-color: rgba(178, 34, 34, 0.3);
}

.label_auth_input>.label_icon {
    font-size: 40px;
    color: #f5f5f5;
}

.label_auth_input:active>.label_icon {
    color: #8c52ff;
}

.medium-textarea {
    min-height: 450px;
    width: 100%;
    outline: none;
    font-family: "Lora", Georgia, serif;
    padding: 16px 14px;
    font-size: 20px;
    line-height: 1.7;
    font-weight: 300;
    border: 1px solid #ccc;
    border-radius: 8px;
    color: rgba(0, 0, 0, 0.8);
    line-height: 1.5;
    position: relative;
}